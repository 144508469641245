{
  "name": "bx-front-widget-mapa-pickup",
  "version": "0.1.0",
  "private": true,
  "dependencies": {
    "@datadog/browser-rum": "^5.4.0",
    "@react-google-maps/api": "^2.18.1",
    "@react-google-maps/marker-clusterer": "^2.16.1",
    "@testing-library/jest-dom": "^5.16.5",
    "@testing-library/react": "^13.4.0",
    "@testing-library/user-event": "^13.5.0",
    "@types/react-gtm-module": "^2.0.1",
    "dotenv": "^16.0.3",
    "feather-icons-react": "^0.6.2",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "react-google-places-autocomplete": "^3.4.0",
    "react-gtm-module": "^2.0.11",
    "react-places-autocomplete": "^7.3.0",
    "react-scripts": "5.0.1",
    "react-to-webcomponent": "^1.7.3",
    "sass": "1.55.0",
    "web-vitals": "^2.1.4"
  },
  "scripts": {
    "start": "parcel src/index.pug --port 3000",
    "build": "parcel build src/index.pug --dist-dir=build --public-url ./"
  },
  "eslintConfig": {
    "extends": [
      "react-app",
      "react-app/jest"
    ]
  },
  "browserslist": {
    "production": [
      "last 1 chrome version",
      "last 1 firefox version",
      "last 1 safari version"
    ],
    "development": [
      "last 1 chrome version",
      "last 1 firefox version",
      "last 1 safari version"
    ]
  },
  "devDependencies": {
    "@parcel/transformer-pug": "^2.8.3",
    "@parcel/transformer-sass": "^2.8.3",
    "@parcel/transformer-svg-react": "^2.8.3",
    "parcel": "^2.8.3",
    "process": "^0.11.10"
  }
}
