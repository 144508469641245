// ./src/libs/datadog.ts
import packageJson from "../../package.json";

export async function initializeDataDog(): Promise<void> {
  const datadogEnv = process.env.REACT_DD_APP_ENV ?? "develop";
  if (datadogEnv !== "production") return;
  const { datadogRum } = await import("@datadog/browser-rum");
  const applicationId = process.env.REACT_DD_APPLICATION_ID ?? "undefined";
  const clientToken = process.env.REACT_DD_CLIENT_TOKEN ?? "undefined";
  datadogRum.init({
    applicationId,
    clientToken,
    site: "datadoghq.com",
    service: packageJson.name,
    version: packageJson.version,
    env: datadogEnv,
    sessionSampleRate: 25,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "allow",
  });
  //console.debug(datadogRum.getInitConfiguration()); // Para verificar que se inicializa correctamente la librería
}
